/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../components/scss/main-stylesheet.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
        
        <main>
          
          {children}
        
        </main>

        <footer>
          <div className="content-container">
            <div className="copyright-wrapper">
              <p><span className="company-detail">&copy; {new Date().getFullYear()} All Rights Reserved - EveryOne a Division of Metricon Homes Pty Ltd, 501 Blackburn Road, Mt Waverley VIC 3149</span></p>
            </div>
            <div className="site-links-wrapper">
              <p>
                <a href="/terms">Terms & Conditions</a>
                <a href="/privacy-policy">Privacy Policy</a>
                <a href="/contact-us">Contact</a>
                <a href="https://au.linkedin.com/showcase/everyone-a-division-of-metricon-homes" target="_blank">LinkedIn</a>
              </p>
            </div>
          <div className="disclaimer-wrapper"><p>NSW Contractor's Licence: 174699C (Metro/Southern), NSW Contractor's Licence: 36654C (Northern), SA Builder's Licence: BLD231776, QLD - QBCC licence: 40992, VIC Builder's Licence: CDB-U 52967.</p></div>

          </div>
        </footer>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
